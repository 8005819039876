.education-heading {
  font-size: 40px;
  font-weight: 400;
}

.education-section {
  display: flex;
  flex-direction: column;
  width: 90%;
  padding: 20px 0px;
  margin: 0px auto;
  margin-top: 2rem;
}

.education-section > * {
  flex: 1;
  margin-bottom: 30px;
}

.education-card-container {
  display: flex;
  flex-direction: column;
  padding-top: 20px;
}

@media (max-width: 1380px) {
  .education-card-container {
    padding-top: 0px;
  }
  .education-heading {
    font-size: 35px;
  }
  .education-text-subtitle {
    font-size: 18px;
    text-align: center;
  }
}

@media (max-width: 768px) {
  .education-card-container {
    padding-top: 0px;
  }
  .education-heading {
    font-size: 30px;
    text-align: center;
  }
  .education-text-subtitle {
    font-size: 16px;
  }
}
