.project-title {
    font-size: 52px;
    font-weight: 400;
    line-height: normal;
  }
  
  .startup-project-text img {
    max-width: 100%;
    height: auto;
  }
  
  .repo-cards-div-main {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    gap: 1rem 1rem;
  }
  
  /* Media Query */
  @media (max-width: 1380px) {
    .project-title {
      font-size: 40px;
    }
  }
  @media (max-width: 768px) {
    .project-title {
      font-size: 30px;
      text-align: center;
    }
    .repo-cards-div-main {
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    }
  }
  